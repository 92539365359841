import React from "react"
import {
  Wrapper,
  Article,
  Photo,
  Title,
  Text,
  MobileLead,
  ContentWrapper,
} from "./styles"
import ModalButton from "src/components/ModalButton"
import AudioPlayer from "src/components/AudioPlayer"
import AudioDescription from "src/components/AudioDescription"
import { isMobile } from "react-device-detect"

const getAudio = attachments => {
  return attachments?.filter(attachment => attachment?.Audio)
}

const ArticleRow = ({
  articles = [],
  variant = "short",
  notitle = false,
  nophoto = false,
} = {}) => {
  return (
    <Wrapper>
      {articles.map((article, index) => {
        return (
          <Article key={`${article?.Id}${index}`}>
            {!nophoto && (
              <Photo
                alt={`${article?.Attachments[0]?.Name}`}
                src={`${article?.Attachments[0]?.Photo}?format=400`}
                loading="lazy"
              />
            )}
            <ContentWrapper>
              {!notitle && <Title tabindex="0">{article?.Title}</Title>}
              {variant !== "short" && (
                <>
                  <br />
                  <br />
                  {!isMobile && <Text tabindex="0">{article?.Lead}</Text>}
                </>
              )}
              {article?.Content && (
                <ModalButton
                  tabindex="0"
                  title={article?.Title}
                  lead={article?.Lead}
                  content={article?.Content}
                  photo={null}
                  audio={getAudio(article?.Attachments)}
                />
              )}
              <br />
              {isMobile && article?.Lead && (
                <MobileLead>{article.Lead}</MobileLead>
              )}
              {getAudio(article?.Attachments)?.map((audio, index) => (
                <div key={audio?.UID}>
                  <AudioDescription
                    content={audio?.Description}
                    noBreak={index !== 0}
                    key={audio?.UID}
                  />
                  <AudioPlayer src={audio?.Audio} />
                </div>
              ))}
            </ContentWrapper>
          </Article>
        )
      })}
      {new Array(3 - articles.length).fill("filler").map((e, i) => (
        <Article key={`article__${i}`}></Article>
      ))}
    </Wrapper>
  )
}

export default ArticleRow
