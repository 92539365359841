import React from "react"
import SEO from "../components/seo"
import Chapter4 from "src/views/Chapter4"
import OGImage from "../images/seo/glosy_radiowego_teatru.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Głosy Teatru Polskiego"
        description="Teatr Polskiego Radia przyciągał na przestrzeni ponad dziewięciu dekad najwybitniejszych twórców i aktorów."
        image={OGImage}
      />
      <Chapter4 />
    </>
  )
}

export default IndexPage
