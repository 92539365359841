import React from "react"
import ArticleRow from "src/components/ArticleRow"

const ArticleRows = ({
  articles = [],
  variant = "medium",
  notitle = false,
  nophoto = false,
} = {}) => {
  return Array(Math.ceil(articles.length / 3))
    .fill("row")
    .map((_, index) => {
      if (
        articles.length % 3 !== 0 &&
        index === Math.ceil(articles.length / 3) - 1
      ) {
        return (
          <ArticleRow
            articles={articles.slice(
              index * 3,
              index * 3 + (articles.length % 3)
            )}
            variant={variant}
            notitle={notitle}
            nophoto={nophoto}
          />
        )
      } else {
        return (
          <ArticleRow
            articles={articles.slice(index * 3, index * 3 + 3)}
            variant={variant}
            notitle={notitle}
            nophoto={nophoto}
          />
        )
      }
    })
}

export default ArticleRows
